import React, { useContext } from 'react';

import { LangContext } from 'context/LangContext';
import * as style from 'styles/components/subPartners/list.module.scss';
import useSupportPartnersPage from 'data/queries/useSupportPartnersPage';
import PartnerMenu from 'components/subPartners/partnerMenu';
import PartnerBoxes from 'components/subPartners/partnerBoxes';
import PartnerList from 'components/subPartners/partnerList';
import Banner from 'components/misc/Banner';
import { getFormattedMessage } from 'components/utils/FormattedMessage';
import TextEditorRender from 'components/utils/TextEditorRender';

const SupportPartners = () => {
    const { lang } = useContext(LangContext);
    const data = useSupportPartnersPage();

    return (
        <div>
            {data.bannerImage && data.title && (
                <Banner
                    image={data.bannerImage.image}
                    title={getFormattedMessage('partners.ourPartners', lang)}
                />
            )}
            <div className={`${style.partnerList__content} wrapper`}>
                <PartnerMenu
                    activePartner="supportPartners"
                />
                <main>
                    {lang?.hreflang && data?.title[lang.hreflang] ? (
                        <h2 className="title-sm-mobile title-lg text-semibold">
                            {data.title[lang.hreflang]}
                        </h2>
                    ): null}

                    {data.description && data.description[lang.translationKey] ? (
                        <div className={style.partnerList__description}>
                            <TextEditorRender content={data.description[lang.translationKey]._rawContent} />
                        </div>
                    ): null}

                    {data.supportPartnersWithLogo.length !== 0 && (
                        <PartnerBoxes
                            headline=""
                            boxSize="sm"
                            partners={data.supportPartnersWithLogo}
                        />
                    )}

                    {data.supportPartners.length !== 0 && (

                        data.supportPartners.map((item) => (
                            <PartnerList
                                headline={item.title}
                                partners={item.supportPartnersWithoutLogo}
                            />
                        ))
                    )}
                </main>
            </div>
        </div>
    );
};

export default SupportPartners;
