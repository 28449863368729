import { useStaticQuery, graphql } from 'gatsby';

const useSupportPartnersPage = () => {
    const { sanityPageSupportPartners: PageSupportPartners } = useStaticQuery(graphql`
        query {
            sanityPageSupportPartners {
                bannerImage {
                    image {
                        ...ImageWithPreview
                    }
                    alt
                }
                title {
                    en
                    se
                }
                description {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                supportPartnersWithLogo {
                    title
                    url
                    logo {
                        ...ImageWithPreview
                    }
                }
                supportPartners {
                    title
                  		supportPartnersWithoutLogo {
                        title
                        url
                    }
                }
            }
        }
    `);

    return PageSupportPartners;
};

export default useSupportPartnersPage;
